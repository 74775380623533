<template>
  <en-dialog :model-value="modelValue" width="80%" title="回访历史列表" @close="$emit('update:model-value', false)">
    <form-query :model="form.data" :method="table.get">
    <en-form-item label="单据搜索">
      <en-input v-model="form.data.quickSearch"></en-input>
    </en-form-item></form-query>
    <table-dynamic
      :height="600"
      code="SVRTVIHYFLD"
      :data="table.data"
      :loading="table.loading"
      :paging="table.paging"
      :method="table.get"
      pagination
      :config="table.config"
    >
    <template #SERIAL_NO="{ row }: { row: EnocloudServiceDefinitions['ServiceReturnVisitHistoryDto']}">
        {{ row.service.serialNo }}
      </template>
      <template #SERVICE_CATEGORY="{ row }: { row: EnocloudServiceDefinitions['ServiceReturnVisitHistoryDto']}">
        {{row.service.serviceCategory.name }}
      </template>
      <template #ADVISOR_NAME="{ row }: { row: EnocloudServiceDefinitions['ServiceReturnVisitHistoryDto']}">
        {{row.service.advisor.name }}
      </template>
      <template #VEHICLE_SPEC="{ row }: { row: EnocloudServiceDefinitions['ServiceDto'] }">
                      {{ row.vehicle?.vehicleSpec.join(',') }}
                    </template>
      <template #PLATE_NO="{ row }: { row: EnocloudServiceDefinitions['ServiceReturnVisitHistoryDto']}">
        {{ row.service.vehicle.plateNo }}
      </template>
      <template #STATUS="{ row }: { row: EnocloudServiceDefinitions['ServiceReturnVisitHistoryDto']}">
        {{  row.status.code === 'Y' ? '成功' : '失败' }}
      </template>
      
      <template #LEVEL="{ row }: { row: EnocloudServiceDefinitions['ServiceReturnVisitHistoryDto']}">
        {{ row.level }}
      </template>
    
    
    </table-dynamic>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      form:{
        data:{
          quickSearch: ''
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/return/visit/history',
            data: 'array',
            loading: true,
            pagination: true,
            params(params){
              params.payload = this.form.data
            }
            
          }
        },
        
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
